import './header.scss';
import logo from '../assets/images/logo.svg'
export default function Header() {
    return (
        <>
            <div className='header'>
                <div className='headerContainer'>
                    <div className='logo'>
                        <img src={logo} className='logoImage' />
                    </div>
                    <div className='buttonsContainer'>
                        <div className='linkContainer linkContainerSelected'>
                            <a className='linkButton linkButtonSelected' target='_blank'>
                                Wrapped Litecoin™
                            </a>
                        </div>
                        <div className='linkContainer'>
                            <a className='linkButton' href='https://www.litecoin.net/' target='_blank' rel="noreferrer" >
                                Litecoin
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
